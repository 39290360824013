import useAppContext from '../../../hooks/useAppContext'
import { H5, ParagraphSmall } from 'baseui/typography'

import { ContainerMain, ControladorComponent } from './components/DataUser'
import { iUserData } from '@/contexts/AppContext'
  
import { useForm } from 'react-hook-form'
import { emailRegex } from '../../../utils'
import { DatePicker } from 'baseui/datepicker'
import { es } from 'date-fns/locale'
 
function DatosUser() {
  const { dataGame, dataUser, setDataUser } = useAppContext()

  const {  control, getValues ,setValue } = useForm<iUserData>({
    defaultValues: dataUser,
  })

  const setDataForm = async (key: keyof iUserData, value: string | boolean) => {
    setDataUser(data => ({
      ...data,
      [key]: value,
    }))
  }

  return (
    <ContainerMain>
      <H5 margin={'20px 20px 5px'} color="#fff" $style={{ textAlign: 'left' }}>
        {/* {dataGame?.nameGame} */}
      </H5>
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <ParagraphSmall margin={'5px 0px 15px'} color={'#fff'}>
          Escribe aquí los datos
        </ParagraphSmall>
        <form
          onChange={({ target }) => {
            const name = (target as any)?.name,
              value = (target as any)?.value
            if (name && value) {
              setDataForm(name, value)
              console.log(getValues(), "valores")
              const filtrado = Object.values(getValues()).filter(item => item === '')
              console.log(filtrado, "filtradooooooo")
              if (filtrado.length === 0) {
                setDataForm('isComplete', true)
              }
            }
          }}
        >
          <ControladorComponent
            label="Nombre"
            control={control}
            isDisabled={dataGame.tutorial}
            name="fullname"
            maxLength={50}
            placeholder="Ingrese su nombre"
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
          />
          {
            dataGame.name === 'POEV' &&
          <ControladorComponent
            label="Correo electrónico"
            control={control}
            isDisabled={dataGame.tutorial}
            name="email"
            placeholder="Ingrese su correo"
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
              pattern: {
                value: emailRegex,
                message: 'Email inválido',
              },
            }}
            typeInput="email"
          />

        }

          <ControladorComponent
            label="Cargo"
            control={control}
            name="post"
            isDisabled={dataGame.tutorial}
            placeholder="Ingrese su cargo"
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
          />

          {
             dataGame.name === 'POEV' &&
          <ControladorComponent
            label="Empresa"
            control={control}
            name="company"
            isDisabled={dataGame.tutorial}
            placeholder="Ingrese su empresa"
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
          /> }

          <ControladorComponent
            label="Planta"
            control={control}
            name="plant"
            isDisabled={dataGame.tutorial}
            placeholder="Ingrese su planta"
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
          />
  {dataGame.tutorial
             ? 
             <ControladorComponent
             label="Fecha"
             control={control}
             name="date"
             isDisabled={dataGame.tutorial}
             placeholder="YYYY/MM/DD"
           />
            :          
          <div style={{ margin: '6px 0px' }}>
            <ParagraphSmall margin={'0px 0px 5px'} color="#fff" $style={{ fontWeight: '500' }}>
              Fecha
            </ParagraphSmall>
            <DatePicker
              onChange={date => {
                if (date.date) {
                  try {
                    const fecha = date.date.toLocaleString('es-CL').slice(0, 10)
                    setDataForm('date', fecha)
                    setValue('date',fecha)
                    const filtrado = Object.values(getValues()).filter(item => item === '')
                    if (filtrado.length === 0) {
                      setDataForm('isComplete', true)
                    }
                  } catch (error) {
                    console.log(error)
                  }
                }
              }}
              locale={es}
              overrides={{ WeekdayHeader: { style: { fontFamily: 'Roboto' } } }}
            />
          </div>
        }
        </form>
      </div>
    </ContainerMain>
  )
}

export default DatosUser
