import { Modal, ModalBody } from 'baseui/modal'
import { SetStateAction, useEffect, useState } from 'react'
import useAppContext from '../../hooks/useAppContext'
import { styled } from 'baseui'
import { H1, ParagraphMedium } from 'baseui/typography'
import Buttons from '../Common/Buttons'
import ImagenLoader from '../Image'
import { ArrowLeft, ArrowRight } from 'baseui/icon'
import { Button } from 'baseui/button'
import { Icons } from '../../assets/Icons'
import ModalImage from './ModalImage'
import { ButtonElements } from '../Tutorial/components'

interface modalProps {
  isActive: boolean,
  closeModal?: (value: SetStateAction<boolean>) => void
}

const ContainerFilas = styled('div', props => ({
  display: 'flex',
  paddingLeft: '10px',
  paddingRight: '10px',
  paddingBottom: '10px',
  paddingTop: '30px',
}))

const ContainerInfo = styled('div', props => ({
  width: '50%',
  paddingTop: '10px',
  paddingLeft: '20px',
  paddingRight: '20px',
}))

const ContainerImage = styled('div', props => ({
  maxHeight: '70vh',
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '.5em',
  position: 'relative',
}))

function ModalInfoGame({ isActive = false, closeModal = () => { } }: modalProps) {
  const { dataGame } = useAppContext()
  const [activeModal, setActive] = useState(false)

  const [imageActive, setImage] = useState({
    url: '',
    key: 0,
    description: '',
    title: '',
  })

  useEffect(() => {
    // solo tarjetas
    console.log(dataGame, "datos")
    if (dataGame.images && dataGame.name === 'Tarjetas') {
      if (dataGame.images.length > 0) {
        setImage({
          url: dataGame.images[0].url,
          key: 0,
          description: dataGame.images[0].description,
          title: dataGame.images[0].title,
        })
      }
    }
  }, [dataGame])

  const changeImage = (type: 'back' | 'next') => {
    try {
      if (type === 'back') {
        const keyNow = imageActive.key
        const keyNext = keyNow === 0 ? dataGame.images.length - 1 : keyNow - 1
        setImage({
          key: keyNext,
          url: dataGame.images[keyNext].url,
          description: dataGame.images[keyNext].description,
          title: dataGame.images[keyNext].title,
        })
        return
      }
      const keyNow = imageActive.key
      const keyNext = keyNow === dataGame.images.length - 1 ? 0 : keyNow + 1
      setImage({
        key: keyNext,
        url: dataGame.images[keyNext].url,
        description: dataGame.images[keyNext].description,
        title: dataGame.images[keyNext].title,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const closeModalImage = () => {
    setActive(false)
  }

  return (
    <>
      <ModalImage isActive={activeModal} closeModal={closeModalImage} urlImage={dataGame.imageRef} />
      <Modal
        overrides={{
          Dialog: { style: { backgroundColor: '#F5F5F5', width: '70%', overflow: 'hidden' } },
          Close: { style: { color: '#0091CE' } },
          Root: { style: { zIndex: 99 } },
        }}
        isOpen={isActive}
        role="dialog"
        unstable_ModalBackdropScroll
        onClose={tipo => {
          console.log(tipo)
          if (tipo.closeSource === 'closeButton') {
            closeModal(false)
          }
        }}
      >
        <ModalBody>
          <ContainerFilas>
            <ContainerInfo>
              <H1
                color={'primary'}
              >
                {dataGame.name}
              </H1>
              <ParagraphMedium>{dataGame.description}</ParagraphMedium>
              {dataGame.name === 'Tarjetas' && (
                <>
                  <b>{imageActive.title}</b>
                  <ParagraphMedium>{imageActive.description}</ParagraphMedium>
                </>
              )}
              <Buttons.ButtonNormal
                styles={{
                  fontWeight: 600,
                  paddingTop: '15px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  paddingBottom: '15px',
                }}
                textButton="Entendido"
                type="secondary"
                action={() => closeModal(false)}
              />

            </ContainerInfo>
            <ContainerImage>
              {dataGame.name === 'Tarjetas' ? (
                <>
                  <Button type="button" kind="minimal" size="mini" onClick={() => changeImage('back')}>
                    <ArrowLeft
                      color="primary"
                      size={50}
                      title="Ver la anterior"
                      overrides={{
                        Svg: {
                          style: {
                            cursor: 'pointer',
                          },
                        },
                      }}
                    />
                  </Button>
                  <ImagenLoader
                    src={imageActive.url}
                    style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                    alt="imageGame"
                  />
                  <Button type="button" kind="minimal" size="mini" onClick={() => changeImage('next')}>
                    <ArrowRight
                      color="primary"
                      size={50}
                      title="Ver la siguiente"
                      overrides={{
                        Svg: {
                          style: {
                            cursor: 'pointer',
                          },
                        },
                      }}
                    />
                  </Button>
                </>
              ) : (
                <>
                  <ImagenLoader
                    src={dataGame.imageRef}
                    style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                    alt="imageGame"
                  />
                  <Button onClick={() => setActive(true)} size="mini" $style={{ position: 'absolute', right: '20px', bottom: '20px' }}>
                    <Icons.ZoomIn height={30} width={30} fill="#fff" />
                  </Button>
                </>
              )}
            </ContainerImage>
            <ButtonElements />
          </ContainerFilas>
        </ModalBody>
      </Modal>
    </>
  )
}
export default ModalInfoGame
