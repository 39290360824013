import useAppContext from '../../../hooks/useAppContext'
import { H5, ParagraphSmall } from 'baseui/typography'
import { useEffect, useState } from 'react'
import interactjs from 'interactjs'
import ImagenLoader from '../../../components/Image'
import { styled } from 'baseui'

const ContainerImage = styled('div', props => ({
  flex: '1',
  overflowY: 'auto',
}))

const BoxImage = styled('div', props => ({

  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: '#fff',
  padding: '10px',
  borderRadius: '.25em',
  maxHeight: '200px',
  width : 'max-content',
  margin : '10px auto'
}))

function ImagesGame() {
  const { dataGame , setDataGame } = useAppContext()

  const [heigthEle, setHeigth] = useState('100%')

  const dragMoveListener = (event) => {
    event.preventDefault();
    event.stopImmediatePropagation();
     var target: HTMLElement = event.target
  //   const { x, y } = target.getBoundingClientRect()
   // console.log(target.scrollTop)
   // target.setAttribute("data-y", `${coordsElement.top}`)
    // keep the dragged position in the data-x/data-y attributes
     let positionX = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx 
     let positionY = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
     //console.log(window.scrollY, y, event.dy, event.client)
     console.log(event, "evneto")
    const atributteNew = target.getAttribute('isNew')
    if (!atributteNew) { 
      positionY = (event.y0 - (event.rect.height / 2)).toString()
      positionX = (event.x0 - (event.rect.width / 2)).toString()
      target.setAttribute('isNew', 'true')
      target.style.zIndex = '9'
    }

    

     target.style.top = positionY + 'px'
     target.style.left = positionX + 'px'
     target.style.position = 'absolute'

    target.setAttribute('data-x', positionX)
    target.setAttribute('data-y', positionY)
  }

  useEffect(() => {
    if (dataGame.name !== 'Tarjetas') {
      ;(window as any).dragMoveListener = dragMoveListener

      interactjs('.drag-drop').draggable({
        listeners: {
          move: dragMoveListener,
        },
        autoScroll: true,
        inertia: true,
      }
      )

      interactjs('.parent').dropzone({
        accept: null,
        ondrop: event => {
          const childrenItem: HTMLElement = event.relatedTarget
          childrenItem.style.position = 'relative'
          childrenItem.style.top = 'auto'
          childrenItem.style.left = 'auto'
        },
      })
      const elementParent2 = document.querySelector('.parent2')?.clientHeight
      if (elementParent2) setHeigth(elementParent2 - 150 + 'px')
    }
  }, [dataGame])

  if (!dataGame.images) {
    return (
      <div>
        <h1>Sin imágenes</h1>
      </div>
    )
  }

  const handleChangeType = (e)=>{
    console.log(dataGame.stepActual)
    if(dataGame.stepActual < 0)
    {
      const target = e.target
      const value = target.value
      setDataGame({...dataGame, activoTipoLup: true, gametype: value})
      console.log(value, "valor")
    }
  }

  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
     <H5 margin={'20px 20px 5px'} color='#fff' $style={{textAlign : 'left'}}>
        Imágenes
      </H5>
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <ParagraphSmall $style={{ color: '#fff', fontFamily: 'Roboto', fontWeight: 400, boxShadow: 'none' }}>
          Arrastre la imagen
        </ParagraphSmall>
      </div>
      <ContainerImage style={{ maxHeight: heigthEle }}>
        <div
          style={{
            display: 'grid',
            gap: '0.5rem',
            padding: '0 15px 20px',
            gridTemplateColumns: '1fr',
          }}
          className="parent"
        >
          {
            dataGame.name === 'LUP' ?
            dataGame.multipleImages[dataGame.stepActual !== 5 ? 'operacion' : dataGame.gametype]?.map((img, key) => (
              <BoxImage
                key={key}
                className="drag-drop"
                fb-id={img.id}
              >
                <ImagenLoader
                  src={img.url}
                  style={{ objectFit: 'contain' }}
                  width="100%"
                  height="100%"
                  alt="preview"
                />
              </BoxImage>
            ))
            :
           dataGame.images.map((img, key) => (
            <BoxImage
              key={key}
              className="drag-drop"
              fb-id={img.id}
            >
              <ImagenLoader
                src={img.url}
                style={{ objectFit: 'contain' }}
                width="100%"
                height="100%"
                alt="preview"
              />
            </BoxImage>
          ))
          }
        </div>
      </ContainerImage>
    </div>
  )
}

export default ImagesGame