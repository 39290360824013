import useAppContext from '../../hooks/useAppContext'
import { styled } from 'baseui'
import { PanelItems } from './PanelItems'

const Container = styled('div', props => ({
  background: props.$theme.colors.primaryA,
  width: '300px',
  // height : '100%',
  flex: 'none',
  boxShadow: '1px 0px 1px rgba(0, 0, 0, 0.15)',
  WebkitBorderTopRightRadius: "50px",
  borderBottomRightRadius: '50px',
  overflow: 'hidden',
  '@media (max-width : 1200px)': {
    width: '260px'
  }
}))

function PanelsList() {
  const { activePanel, stepActive } = useAppContext()

  const Component = PanelItems[activePanel]

  return <Container style={{ zIndex: stepActive > 1 && stepActive < 5 ? 9 : 1 }}>{Component && <Component />}</Container>
}

export default PanelsList
