export interface iImages {
  /** URL de la imagen */
  url: string
  /** En POEV Y LUP si dicen los numeros pero en tarjetas solo una tarjeta será la buena por ende las demas son false */
  position?: number | boolean
  /** Sirve solo en POEV Y LUP */
  id?: string
  // variables para tarjetas
  /** Titulo de la tarjeta */
  title?: string
  /** Descripcion de la tarjeta */
  description?: string
  /** Tipo de carta en tarjetas */
  typeCard?: string
}
export interface iDataGame {
  activoTipoLup: boolean,
  name: string
  id: string
  nameExtend: string
  /** Descripcion del modal de inicio de juego */
  description: string
  /** Lista de imagenes, en tarjetas tiene diferente funcion */
  images: iImages[]
  /** Imagen que sale en el modal de inicio, para tarjetas estos serán reemplazados por images */
  imageRef?: string
  /** fondo del modal de inicio */
  color: string // 
  /** color de boton del modal de cuando inicia el juego*/
  buttonColor: string,
  /** Numeros de pasos solo LUP Y POEV */
  numberStepGame?: number,
  /** Numeros de pasos de  tutorial lup poev */
  numberStepTutorial: number
  /** Descripcion del juego en Tarjetas */
  descriptionGame?: string
  /** Imagen del caso en Tarjetas */
  imageCase?: string
  /** Descripcion inicial */
  descriptionInitial: string
  /** Nombre del juego */
  nameGame?: string,
  stepActual: number,
  gametype: string, 
  multipleImages: object,
  tutorial: boolean
}

export interface iItemTutorial {
  title: string,
  description: string,
  buttonName: string
}

export const itemsTutorialLup: iItemTutorial[] = [
  {
    title: 'Instrucciones',
    description: "Para crear tu LUP deberás completar los siguientes pasos",
    buttonName: 'Continuar'
  },
  {
    title: 'Identifica tu LUP',
    description: "Deberás escribir tus datos personales, de la empresa y otros datos relevantes en esta columna",
    buttonName: 'Siguiente'
  },
  {
    title: 'Selecciona una categoría',
    description: "Deberás elegir el tipo de LUP que quieres crear. Tus opciones son Operación, Calidad, Mantención o Seguridad.",
    buttonName: 'Siguiente'
  },
  {
    title: 'Arrastra los elementos',
    description: "Luego encontrarás elementos correctos e incorrectos en la galería. Deberás arrastrarlos hacia el documento y ubicarlos en el lugar que corresponda.",
    buttonName: 'Siguiente'
  },
  {
    title: 'Termina y revisa',
    description: "Para finalizar, deberás hacer clic en “Enviar y revisar y tu LUP será evaluada. Podrás saber de inmediato si está correcta o si es necesario corregir algún detalle. ¡Y listo! Ya sabes cómo crear tu LUP",
    buttonName: '¡Comencemos!'
  },
]

export const itemsTutorialPoev: iItemTutorial[] = [
  {
    title: 'Instrucciones',
    description: "Para crear un POEV, completa los siguientes pasos:",
    buttonName: 'Continuar'
  },
  {
    title: 'Identifica tu POEV con la información básica',
    description: "Escribe tus datos personales, de la empresa y otros datos relevantes en esta columna",
    buttonName: 'Siguiente'
  },
  {
    title : 'Arrastra los pasos hacia el documento',
    description : "Identifica los pasos disponibles en la galería (Hay correctos e incorrectos).Arrastra uno a uno, solo los que consideres necesarios y ubícalos en el documento siguiendo una secuencia lógica.",
    buttonName : 'Siguiente'
  },
  {
    title: 'Revisa y envía tu ejercicio',
    description: "Cuando completes tu ejercicio haz clic en “Enviar y revisar” y tu POEV será evaluado. Sabrás de inmediato si está correcta o si fuera necesario modificar algún detalle. ¡Y listo! Ya sabes cómo crear tu POEV",
    buttonName: '¡Comencemos!'
  },
] 
