import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import Editor from "./screens/index";
import Lup from './screens/lup';
import Poev from './screens/poev'

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" element={<Editor />}></Route>
        <Route path="/lup" element={<Lup />}></Route>
        <Route path="/poev" element={<Poev />}></Route>
      </Switch>
    </Router>
  );
};
 
export default Routes;
