import useAppContext from '../hooks/useAppContext';

import { styled, useStyletron } from 'baseui'
import { Button } from 'baseui/button';

import bkHome from '../assets/images/bk.png'
import logo from '../assets/logo.png'
import { useCallback, useEffect } from 'react';
import { H3, ParagraphMedium } from 'baseui/typography';
import { backgroundClip } from 'html2canvas/dist/types/css/property-descriptors/background-clip';

const ContainerWelcome = styled('div', props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
}))

const ContainerMain = styled('div', props => ({
  color: '#fff',
  width: '60%',
  borderRadius: props.$theme.borders.radius400,
  boxShadow: props.$theme.lighting.shadow700,
  zIndex: 9999,
  textAlign: 'center',
  padding: '10px 20px 20px'
}))

const ContainerImage = styled('div', props => ({
  width: '200px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '160px',
  marginTop: '-125px',
  borderRadius: props.$theme.borders.radius500,
  boxShadow: "3px 3px 6px #0000006f",
  backgroundColor: "#fff"
}))


function WelcomeComponent() {
  const [css] = useStyletron()
  const { setActiveWelcome, dataGame } = useAppContext()


  const nextStep = useCallback(() => {
    setActiveWelcome(false)
  }, [setActiveWelcome])

  useEffect(() => {
    document.addEventListener('keypress', (e) => {
      if (e.key === 'Enter' || e.code === 'Enter') {
        nextStep();
      }
    })
  }, [nextStep])


  useEffect(() => {
    document.querySelector("main").style.height = "100vh"
    return () => {
      document.querySelector("main").style.height = "auto"

    }
  },
    [])


  return (
    <ContainerWelcome>
      <img
        alt='background-web'
        className={css({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        })}
        src={bkHome}
      />
      {/* <ContainerBottom>
        <ElementBottom style={{ backgroundColor : '#F5BD00'}}/>
        <ElementBottom style={{ backgroundColor : '#0091CE'}}/>
        <ElementBottom style={{ backgroundColor : '#000000'}}/>
        <ElementBottom style={{ backgroundColor : '#EB0F00'}}/>
      </ContainerBottom> */}
      <ContainerMain style={{ backgroundColor: dataGame.color }}>
        <ContainerImage>
          <img alt='logo' style={{ maxWidth: '90%', height: 'auto', objectFit: 'contain' }} src={logo} />
        </ContainerImage>
        <H3 margin={'20px 0px'} color='#fff'>Bienvenidos a la fábrica de {dataGame.name}</H3>
        <ParagraphMedium color={'#fff'} $style={{ fontWeight: '600' }}>
          {dataGame?.descriptionInitial}
        </ParagraphMedium>
        <Button onClick={nextStep} overrides={{
          BaseButton: {
            style: {
              backgroundColor: dataGame.buttonColor,
            }
          }
        }}>Comenzar</Button>
      </ContainerMain>
    </ContainerWelcome>
  )
}

export default WelcomeComponent
