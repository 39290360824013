import { useEffect, useState } from 'react'

function Lup(){

    useEffect(()=>{
        window.location.href = window.location.origin + '?id=LUP-01&name=GOOGLE';
    }, [])

    return (<></>)
}

export default Lup