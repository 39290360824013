import { useEffect, useState } from 'react'

function Poev(){

    useEffect(()=>{
        window.location.href = window.location.origin + '?id=POEV-01&name=GOOGLE';
    }, [])

    return (<></>)
}

export default Poev