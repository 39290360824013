import {Button, KIND} from 'baseui/button';
import { CSSProperties } from 'react';

interface iButton{
  textButton : string,
  action?: () => void,
  type?: KIND[keyof KIND],
  styles : CSSProperties,
  typeButton?: 'submit' | 'reset' | 'button',
  isLoader?: boolean
}

export const ButtonNormal = ({textButton = '', action = () =>{} , type  = KIND.primary, styles,typeButton = 'button', isLoader= false} : iButton ) =>{
  return(
    <Button isLoading={isLoader} $style={{...styles,fontFamily : 'Roboto'}} type={typeButton} kind={type} onClick={action}>{textButton}</Button>
  )
}