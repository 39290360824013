
import { useStyletron } from 'baseui';
import { Modal, ModalBody } from 'baseui/modal'
import { H2, ParagraphMedium } from 'baseui/typography';
import { SetStateAction } from 'react'
import {Icons} from '../../assets/Icons';
import Button from '../Common/Buttons';

interface iModalProps {
  isActive: boolean
  closeModal?: (value: SetStateAction<boolean>) => void
}

function ModalEleccionIncorrecta({ isActive = false, closeModal = () => {} }: iModalProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [css, theme] = useStyletron()
  return (
    <Modal
      overrides={{
        Dialog: { style: { backgroundColor: '#F5F5F5', width: '40%' } },
        Close: { style: { color: '#0091CE' } },
        Root: { style: { zIndex: 99 } },
      }}
      isOpen={isActive}
      role="dialog"
      unstable_ModalBackdropScroll
      onClose={tipo => {
        if (tipo.closeSource === 'closeButton') {
          closeModal(false)
        }
      }}
    >
      <ModalBody>
        <div style={{padding : '40px 10px'}}>
          <div style={{textAlign : 'center'}}>
            <Icons.Error size={122} />
            <H2 $style={{color : '#0091CE'}}>¡Elección incorrecta!</H2>
            <ParagraphMedium $style={{fontWeight : 500}}>
              Lo sentimos escogiste mal la tarjeta, puedes volver a intentarlo.
            </ParagraphMedium>
            <div style={{display : 'flex', flexDirection : 'column', width : '30%', margin :'auto'}}>
              <Button.ButtonNormal type='secondary' textButton='Modificar' action={() => closeModal(false)} styles={{ marginTop : '10px', marginBottom : '10px', boxShadow : theme.lighting.shadow600, fontWeight :'bold'}} />
            </div>
          </div>
        </div>
        
      </ModalBody>
    </Modal>
  )
}

export default ModalEleccionIncorrecta
