import { styled } from 'baseui'
import { ParagraphSmall } from 'baseui/typography'

export const ContainerPoev = styled('div', () => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  padding : '20px 0px 20px 20px',
  boxSizing : 'border-box'
}))

export const ContainerData = styled('div', () => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3,1fr)',
  width: '90%',
  margin: 'auto',
  padding : '10px 0px 15px',
  '@media (max-width : 1200px)' : {
    width : '95%'
  },
  '@media (max-width : 870px)' : {
    gridTemplateColumns: 'repeat(2,1fr)',
  }
}))

export const ContainerMiddle = styled('div', props => ({
  border: '1px solid #ccc',
  backgroundColor : '#fff',
  width: '100%',
  borderRadius: '.25em',
  padding: '10px',
  maxHeight : '100%',
  overflowY : 'auto',
  boxSizing : 'border-box'
}))

export const ContainerInput = styled('div', props => ({
  backgroundColor: '#eee',
  border: '1px solid #ccc',
  padding: '10px',
  width: '80%',
  borderRadius: '.25em',
  wordBreak: 'break-all',
}))

export const ContainerParents = styled('div',pros => ({
  borderTop: '1px solid #ddd',
  padding: '10px 0px',
  display: 'grid',
  gridTemplateColumns: '25% 25% 25% 25%',
  '@media (max-width : 1400px)': {
    gridTemplateColumns : '33% 33% 33%'
  },
  '@media (max-width : 1025px)': {
    gridTemplateColumns : '50% 50%'
  },
}))

export const ContainerParentsLup = styled('div',pros => ({
  padding: '10px 0px',
  display: 'grid',
  width : '60%',
  margin : '0px auto ',
  gridTemplateColumns: '50% 50%',
  '@media (max-width : 1500px)' : {
    width : '70%'
  },
  '@media (max-width : 1400px)' : {
    width : '80%'
  },
  '@media (max-width : 1250px)' : {
    width : '90%'
  },
  '@media (max-width : 1120px)' : {
    width : '100%'
  }
}))

export const ContainerButton = styled('div',props => ({

  display: 'flex',
  justifyContent: 'center',
  position: 'fixed',
  bottom: '2%',
  right: '2%',
  zIndex: 1, 
}))

interface iItemP {
  active: boolean
  title: string
}

export const ItemP = (props: iItemP) => {
  return (
    <ContainerInput>
      {props.active ? (
        <ParagraphSmall margin={0} >{props.title}</ParagraphSmall>
      ) : (
        <ParagraphSmall
          margin={0}
          $style={{ fontStyle: 'italic', fontSize: '12px', color: '#616161' }}
        >
          Completa tus datos
        </ParagraphSmall>
      )}
    </ContainerInput>
  )
}
